exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-denwa-soudan-js": () => import("./../../../src/pages/denwa-soudan.js" /* webpackChunkName: "component---src-pages-denwa-soudan-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mousikomi-form-js": () => import("./../../../src/pages/mousikomi-form.js" /* webpackChunkName: "component---src-pages-mousikomi-form-js" */),
  "component---src-pages-plan-js": () => import("./../../../src/pages/plan.js" /* webpackChunkName: "component---src-pages-plan-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-tank-you-js": () => import("./../../../src/pages/tank-you.js" /* webpackChunkName: "component---src-pages-tank-you-js" */),
  "component---src-pages-toiawase-js": () => import("./../../../src/pages/toiawase.js" /* webpackChunkName: "component---src-pages-toiawase-js" */),
  "component---src-pages-transactions-js": () => import("./../../../src/pages/transactions.js" /* webpackChunkName: "component---src-pages-transactions-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-posts-js": () => import("./../../../src/templates/posts.js" /* webpackChunkName: "component---src-templates-posts-js" */)
}

